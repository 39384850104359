import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { EventEmitter, Injectable } from '@angular/core';
import { TextQuestion } from '../models/conversation';
import { CompletedEvent, Event, EventCssClass, EventState, EventType, OptOutEvent, ReopenEvent } from '../models/event';
import { Template } from '../models/job';
import { ConvsService } from './convs.service';
import { JobsService } from './jobs.service';

import firebase = require('firebase');

@Injectable({
  providedIn: 'root'
})
export class EventMenuService {
  currentConversation;
  private eventEmitter;
  private currentMenu = new BehaviorSubject(null);
  currentMenu$ = this.currentMenu.asObservable().pipe(
    map(context => {
      if (!context || !this.questionOptions.length) {
        return null;
      }
      return {
        context,
        questions: this.questionOptions
      };
    })
  );
  questionOptions: TextQuestion[];
  private eventMenuSelection = new BehaviorSubject<boolean>(false);
  eventMenuSelection$ = this.eventMenuSelection.asObservable();
  private templateSelected = new BehaviorSubject<Template>(null);
  templateSelected$ = this.templateSelected.asObservable();

  constructor(private convsService: ConvsService, private jobsService: JobsService) {
    this.convsService.currentConversation$
      .pipe(
        tap(conv => (this.currentConversation = conv)),
        map(conv => {
          if (conv?.questions?.length) {
            return conv.questions.filter(q => q.controlType === 'text') as TextQuestion[];
          }
          return [];
        })
      )
      .subscribe(options => (this.questionOptions = options));
  }

  openEventMenu(
    event: Event,
    posX: number,
    posY: number,
    eventEmitter: EventEmitter<CompletedEvent | OptOutEvent | ReopenEvent>
  ) {
    this.eventEmitter = eventEmitter;
    this.currentMenu.next({ event, posX, posY });
  }

  closeEventMenu() {
    this.currentMenu.next(null);
  }

  onEventMenuSelection(question: TextQuestion, text: string) {
    this.eventMenuSelection.next(true);
    const q = Object.assign({}, { ...question }, { response: text });
    this.convsService.updateConversationQuestion(this.currentConversation, q);

    if (q.template) {
      const template = this.jobsService.getTemplateById(q.template);
      this.templateSelected.next(template);
    }

    this.closeEventMenu();
  }

  onEndConversation() {
    this.eventEmitter.emit({
      agent: this.currentConversation.activeAgent,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      messageType: EventType.completedEvent,
      text: 'Conversation completed',
      state: EventState.completed,
      cssClass: EventCssClass.completed,
      account_id: this.jobsService.getCurrentJob().account_id,
      disposition: null
    } as CompletedEvent);
    this.closeEventMenu();
  }

  onOptOutConversation() {
    this.eventEmitter.emit({
      agent: this.currentConversation.activeAgent,
      cssClass: EventCssClass.info,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      state: EventState.pending,
      messageType: EventType.optOutEvent,
      text: 'The contact has been opted out',
      phone: this.currentConversation.lead.lead_phone,
      conversation_id: this.currentConversation.id,
      account_id: this.jobsService.getCurrentJob().account_id,
      disposition: null
    } as OptOutEvent);
    this.closeEventMenu();
  }
}
