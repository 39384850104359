import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { isEqual, sortBy } from 'lodash';

import { Agent } from '../models/agent';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { JobsService } from './jobs.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ResponderService {
  currentAgentIsResponder = new BehaviorSubject<boolean>(true);
  fetchingResponders = false;
  lastJobId = new BehaviorSubject<string>(null);
  lastResponderIds = new BehaviorSubject<string[]>([]);
  responders = new BehaviorSubject<Agent[]>([]);
  sub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private jobsService: JobsService,
    private spinnerService: SpinnerService
  ) {
    this.sub = this.jobsService.currentJob$
      .pipe(
        filter(job => !!job),
        map(job => {
          const responderIds = job.agent_ids.filter(id => job.agents[id].is_responder);
          if (job.id === this.lastJobId.value && isEqual(sortBy(responderIds), sortBy(this.lastResponderIds.value))) {
            return null;
          }
          return { job, responderIds };
        }),
        filter(value => !!value),
        map(({ job, responderIds }) => {
          this.lastJobId.next(job.id);
          this.lastResponderIds.next(responderIds);

          if (responderIds.includes(this.authService.agent.value.uida)) {
            console.log('Agent is a responder');
            this.currentAgentIsResponder.next(true);
            return [];
          }

          console.log('Agent is NOT a responder', job);
          this.spinnerService.startSpinner('spinner.loadingResponders');
          this.currentAgentIsResponder.next(false);
          return responderIds;
        }),
        switchMap(ids =>
          Promise.all(
            ids.map(id =>
              this.afs
                .doc<Agent>(`agents/${id}`)
                .get()
                .pipe(
                  map(snapshot => ({ ...snapshot.data() } as Agent)),
                  first()
                )
                .toPromise()
            )
          )
        )
      )
      .subscribe(data => {
        console.log(`Observable responder agents data: `, data);
        this.responders.next(data);
        if (this.spinnerService.isSpinning) {
          this.spinnerService.stopSpinner();
        }
      });
  }

  getNextResponder(): Agent {
    if (this.responders.value.length) {
      const next = this.responders.value[Math.floor(Math.random() * this.responders.value.length)];
      console.log('Returning next responder', next);
      return next;
    }
    console.log('getNextResponder called without any responders!!!');
    return null;
  }

  signOut() {
    this.lastJobId.next(null);
    this.lastResponderIds.next([]);
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
    console.log('Responder service signed out');
  }
}
