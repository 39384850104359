import * as Bowser from 'bowser';

import { Timestamp } from '@firebase/firestore-types';

export interface Agent {
  aid: string;
  contact_manager: boolean;
  display_name?: string;
  domain: string;
  email?: string;
  display_email?: string;
  first_name?: string;
  id: string;
  last_name?: string;
  phone?: string;
  preferred_language?: string;
  status: string;
  team_id?: string;
  uid: string;
  uida: string;
  bypassSkillsCheck: boolean;
  shift?: AgentShift;
  primary_canvasser_id?: string;
}

export interface AgentAssignment {
  uid: string;
  uida: string;
  id: string;
  status: string;
  assigned: boolean;
  display_name: string;
  display_email: string;
  is_responder: boolean;
}

export interface AgentLanguage {
  preferred_language: string;
}

export interface AgentStatus {
  account_id?: string;
  agent_ui_version?: string;
  current_job_id?: string;
  environment?: Bowser.Parser.ParsedResult;
  inbox_count?: number;
  is_active: boolean;
  is_logged_in: boolean;
  is_using_sanm?: boolean;
  just_changed_jobs?: boolean;
  just_logged_in?: boolean;
  just_logged_out?: boolean;
  just_went_inactive?: boolean;
  last_action?: string;
  last_action_date?: Timestamp | number;
  last_action_job_id?: string;
  last_login_date?: Timestamp | number;
  last_login_check?: Timestamp | number;
  previous_job_id?: string;
  transferredConversations?: boolean;
  transmission_rates: AgentTransmissionRates;
  uida?: string;
}

export interface AgentTransmissionRates {
  one_min: number;
  five_min: number;
  fifteen_min: number;
}

export interface AgentShift {
  status: AgentShiftStatus;
  team_start_date?: Timestamp | Date;
  shift_start_date?: Timestamp | Date;
  shift_end_date?: Timestamp | Date;
  last_break_start_date?: Timestamp | Date;
  last_break_end_date?: Timestamp | Date;
  previous_shift_duration?: number; // nanoseconds, thanks golang
  previous_break_duration?: number; // also nano
}

/**
 * This enum holds the current shift state of a global agent.
 */
export enum AgentShiftStatus {
  Unknown = 'UNKNOWN',
  NoShift = 'NO-SHIFT',
  Working = 'WORKING',
  Break = 'BREAK',
  Ended = 'ENDED'
}
