import 'firebase/functions';

import * as Bowser from 'bowser';
import * as firebase from 'firebase/app';

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Agent, AgentStatus, AgentTransmissionRates } from '../models/agent';
import { EventType } from '../models/event';
import { AuthService } from './auth.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  private agentStatusUpdateInterval;
  private currentInboxCount = new BehaviorSubject<number>(0);
  private currentJobId: string;
  private currentStatus: AgentStatus = {} as AgentStatus;
  private currentStatusSubject = new BehaviorSubject<AgentStatus>(this.currentStatus);
  private hasInitialized = false;
  private hasPerformedAction = false;
  private inactivityTimer: NodeJS.Timeout;
  isFastModeEnabled = false;
  private lastActionData: Partial<AgentStatus>;
  private transmissionRateBins = {
    tenSec: [],
    oneMin: [],
    fiveMin: [],
    fifteenMin: []
  };
  private profilingTenSecRates: number[] = [];
  private profilingOneMinRates: number[] = [];

  updateAgentStatusCloudFunction = firebase.functions().httpsCallable('stats-updateAgentStatus');

  constructor(private authService: AuthService, private timeService: TimeService) {
    this.resetCurrentStatus = this.resetCurrentStatus.bind(this);
    this.updateAgentStatus = this.updateAgentStatus.bind(this);

    this.updateAgentStatusDocOnNewStatus();

    this.authService.agent.subscribe(agent => {
      if (!this.hasInitialized && agent) {
        this.initCurrentStatus(agent);
        this.performedAction('login');
        this.startStatusUpdates();
      }
    });
  }

  get currentStatus$() {
    return this.currentStatusSubject.asObservable();
  }

  getCurrentStatus(withLastAction = false) {
    this.currentStatus.last_login_check = this.timeService.now.getTime();
    this.currentStatus.is_using_sanm = this.isFastModeEnabled;
    this.currentStatus.transmission_rates = this.getCurrentTransmissionRates();
    this.currentStatus.inbox_count = this.currentInboxCount.value;

    if (this.hasPerformedAction || withLastAction) {
      return Object.assign(this.currentStatus, { ...this.lastActionData });
    }

    return this.currentStatus;
  }

  performedAction(action: string, options?: Partial<AgentStatus>) {
    this.hasPerformedAction = true;
    this.resetInactivityTimer();

    if (action === 'changed current job') {
      this.currentStatus.just_changed_jobs = true;
      this.currentStatus.previous_job_id = options.previous_job_id;
      this.currentStatus.current_job_id = options.current_job_id;
    }

    if (action === EventType.textEvent || action === EventType.mmsEvent) {
      this.incrementTransmissionRateBins();
    }

    this.lastActionData = {
      is_active: true,
      last_action: action,
      last_action_date: this.timeService.now.getTime(),
      last_action_job_id: this.currentJobId || null,
      transferredConversations: false
    };
  }

  setCurrentInboxCount(count: number) {
    this.currentInboxCount.next(count);
  }

  setCurrentJobId(id: string) {
    this.currentJobId = id;
  }

  startStatusUpdates() {
    if (this.agentStatusUpdateInterval) {
      clearInterval(this.agentStatusUpdateInterval);
    }
    this.updateAgentStatus();
    this.agentStatusUpdateInterval = setInterval(this.updateAgentStatus, 1000 * 10);
    this.hasInitialized = true;
  }

  async signOut(
    { agent, sendSignoutStats }: { agent?: Agent; sendSignoutStats?: boolean } = { agent: null, sendSignoutStats: true }
  ) {
    clearInterval(this.agentStatusUpdateInterval);
    this.hasInitialized = false;
    if (this.authService.isAuthed && sendSignoutStats) {
      await this.updateAgentStatusCloudFunction({
        inbox_count: this.currentInboxCount.value,
        is_active: false,
        is_logged_in: false,
        transferredConversations: false,
        just_logged_out: true,
        last_login_date: this.timeService.now.getTime(),
        last_login_check: this.timeService.now.getTime(),
        last_action: 'logout',
        last_action_date: this.timeService.now.getTime(),
        uida: agent.uida
      }).catch(err => {
        console.error(err);
        throw err;
      });
    }
    console.log('Stats service signed out');
  }

  private filterTransmissionRateBins() {
    const now = this.timeService.now.getTime();
    const oneMinAgo = now - 60 * 1000;
    const fiveMinAgo = now - 5 * 60 * 1000;
    const fifteenMinAgo = now - 15 * 60 * 1000;
    this.transmissionRateBins.oneMin = this.transmissionRateBins.oneMin.filter(x => x > oneMinAgo);
    this.transmissionRateBins.fiveMin = this.transmissionRateBins.fiveMin.filter(x => x > fiveMinAgo);
    this.transmissionRateBins.fifteenMin = this.transmissionRateBins.fifteenMin.filter(x => x > fifteenMinAgo);

    if (!environment.production) {
      const tenSecAgo = now - 10 * 1000;
      this.transmissionRateBins.tenSec = this.transmissionRateBins.tenSec.filter(x => x > tenSecAgo);
    }
  }

  private getCurrentTransmissionRates(): AgentTransmissionRates {
    const secSinceLogin =
      (this.timeService.now.getTime() - (this.currentStatus?.last_login_date as number)) / 1000 || 1;

    const oneMinSec = secSinceLogin < 60 ? secSinceLogin : 60;
    const fiveMinSec = secSinceLogin < 5 * 60 ? secSinceLogin : 5 * 60;
    const fifteenMinSec = secSinceLogin < 15 * 60 ? secSinceLogin : 15 * 60;

    const rates = {
      one_min: +(this.transmissionRateBins.oneMin.length / oneMinSec).toFixed(2),
      five_min: +(this.transmissionRateBins.fiveMin.length / fiveMinSec).toFixed(2),
      fifteen_min: +(this.transmissionRateBins.fifteenMin.length / fifteenMinSec).toFixed(2)
    };

    if (!environment.production) {
      const tenSec = secSinceLogin < 10 ? secSinceLogin : 10;
      this.profilingTenSecRates.push(+(this.transmissionRateBins.tenSec.length / tenSec).toFixed(2));
      this.profilingOneMinRates.push(rates.one_min);
      this.logRateStats();
    }

    return rates;
  }

  private logRateStats() {
    const calculateStats = (rates: number[]) => {
      const avg = rates.reduce((sum, rate) => sum + rate, 0) / rates.length;
      const max = Math.max(...rates);
      return { avg: +avg.toFixed(2), max: +max.toFixed(2) };
    };

    const stats = {
      '10s': calculateStats(this.profilingTenSecRates),
      '1m': calculateStats(this.profilingOneMinRates)
    };

    console.log('Transmission Rate Stats:', stats);
  }

  private incrementTransmissionRateBins() {
    const now = this.timeService.now.getTime();
    this.transmissionRateBins.oneMin.push(now);
    this.transmissionRateBins.fiveMin.push(now);
    this.transmissionRateBins.fifteenMin.push(now);

    if (!environment.production) {
      this.transmissionRateBins.tenSec.push(now);
    }
  }

  private initCurrentStatus(agent: Agent) {
    this.currentStatus = {
      account_id: agent.aid,
      uida: agent.uida,
      agent_ui_version: environment.version,
      environment: Bowser.parse(window.navigator.userAgent),
      last_login_date: this.timeService.now.getTime(),
      last_login_check: this.timeService.now.getTime(),
      is_using_sanm: this.isFastModeEnabled,
      is_logged_in: true,
      is_active: true,
      just_logged_in: true,
      transmission_rates: this.getCurrentTransmissionRates(),
      inbox_count: this.currentInboxCount.value
    };
  }

  private resetCurrentStatus() {
    this.hasPerformedAction = false;
    this.currentStatus.is_active = false;
    this.currentStatus.just_changed_jobs = false;
    this.currentStatus.just_logged_in = false;
    this.currentStatus.just_went_inactive = false;
    this.currentStatus.inbox_count = 0;

    delete this.currentStatus.current_job_id;
    delete this.currentStatus.previous_job_id;
  }

  private resetInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }

    this.inactivityTimer = setTimeout(() => {
      this.currentStatus.just_went_inactive = true;
    }, 5 * 60 * 1000);
  }

  private updateAgentStatus() {
    if (!this.currentStatus) {
      this.resetCurrentStatus();
    }

    this.filterTransmissionRateBins();

    this.currentStatusSubject.next(this.getCurrentStatus());
    this.resetCurrentStatus();
  }

  private updateAgentStatusDocOnNewStatus() {
    this.currentStatus$.subscribe(status => {
      if (!status || !status.uida) {
        return;
      }

      this.updateAgentStatusCloudFunction(status).catch(error =>
        console.error('Failed to update agent status via cloud function: ', error)
      );
    });
  }
}
